import * as React from 'react'
import cn from 'classnames'
import { SelectOption } from '../select.types'

export type TriggerProps = {
  triggerContent?: React.ReactNode
  triggerIcon?: React.ReactNode
  value?: SelectOption | SelectOption[]
}

export const TriggerContent = (props: TriggerProps) => {
  const { triggerContent = 'Select', triggerIcon, value } = props

  let content = triggerContent

  if (value) {
    if (Array.isArray(value)) {
      if (value.length === 1) content = value[0].label
      if (value.length > 1) content = `${value[0].label}, +${value.length - 1}`
    } else {
      content = value.label
    }
  }

  return (
    <div
      className={cn(
        value ? 'text-gray-900' : 'text-gray-600',
        'flex gap-1 items-center truncate'
      )}
    >
      {triggerIcon}
      {content}
    </div>
  )
}
