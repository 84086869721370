import { Dropdown } from 'src/design-system'
import * as React from 'react'
import ReactSelect from 'react-select'
import { SelectOption, SelectProps } from './select.types'
import { SelectVm } from './select-vm'
import { TriggerContent } from './components/trigger'
import * as Components from './components'
import cn from 'classnames'

export const Select = (props: SelectProps) => {
  const {
    contentClassName,
    defaultValue,
    menuIsOpen,
    invalid = false,
    isMulti,
    isSearchable,
    onBlur,
    onChange,
    options,
    placeholder,
    triggerClassName,
    triggerContent,
    triggerIcon,
    value: externalValue,
    align = 'start',
  } = props

  const vm = React.useMemo(() => {
    return new SelectVm(options)
  }, [options])

  const [open, setOpen] = React.useState(!!menuIsOpen)
  const [value, setValue] = React.useState(externalValue || defaultValue)

  React.useEffect(() => {
    setValue(externalValue || defaultValue)
  }, [defaultValue, externalValue])

  return (
    <Dropdown.Root
      onOpenChange={(open) => {
        setOpen(open)
        if (!open) onBlur?.()
      }}
      open={open}
    >
      <Dropdown.Trigger
        className={cn(triggerClassName, invalid && 'border-red-500')}
        variant="styled"
      >
        <TriggerContent
          triggerContent={triggerContent}
          triggerIcon={triggerIcon}
          value={vm.option(value)}
        />
      </Dropdown.Trigger>
      <Dropdown.Content
        align={align}
        avoidCollisions={false}
        className={contentClassName}
        forceMount={menuIsOpen || undefined}
      >
        <ReactSelect
          blurInputOnSelect={!isMulti}
          closeMenuOnSelect={!isMulti}
          components={{
            ...Components,
            ...(!isSearchable && {
              Control: () => null,
              ValueContainer: () => null,
            }),
          }}
          controlShouldRenderValue={false}
          defaultValue={vm.option(defaultValue)}
          hideSelectedOptions={false}
          isMulti={isMulti}
          isSearchable={isSearchable}
          menuIsOpen
          placeholder={placeholder}
          onChange={(newValue) => {
            if (isMulti) {
              const values = (newValue as SelectOption[]).map(
                (option) => option.value
              )
              onChange?.(values)
              setValue(values)
            } else {
              const value = (newValue as SelectOption)?.value
              onChange?.(value)
              setOpen(false)
              setValue(value)
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Escape') setOpen(false)
          }}
          options={options}
          styles={{
            groupHeading: () => ({}),
            menu: () => ({}),
          }}
          value={vm.option(value)}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
