import { components, OptionProps } from 'react-select'
import * as React from 'react'
import cn from 'classnames'
import { CheckboxIcon } from 'components/checkbox-icon'
import { SelectOption } from '../select.types'

export const Option = (props: OptionProps<SelectOption>) => {
  const { isFocused, isMulti, isSelected, label } = props

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-transparent',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900'
      )}
    >
      {isMulti && <CheckboxIcon checked={isSelected} />}
      <span data-selected={isSelected}>{label}</span>
    </components.Option>
  )
}
