import { SelectOption } from './select.types'

export class SelectVm {
  constructor(private options: SelectOption[]) {}

  option(value?: string | string[]) {
    if (!value) return undefined

    if (typeof value === 'string') {
      return this.options.find((option) => option.value === value)
    } else {
      const filteredOptions = this.options.filter((option) =>
        value?.includes(option.value)
      )

      return filteredOptions.length > 0 ? filteredOptions : undefined
    }
  }
}
